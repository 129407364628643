import { render, staticRenderFns } from "./UploadTitulos.vue?vue&type=template&id=0445764f"
import script from "./UploadTitulos.vue?vue&type=script&lang=js"
export * from "./UploadTitulos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dartanghanvani/Documents/workspace/recuperi-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0445764f')) {
      api.createRecord('0445764f', component.options)
    } else {
      api.reload('0445764f', component.options)
    }
    module.hot.accept("./UploadTitulos.vue?vue&type=template&id=0445764f", function () {
      api.rerender('0445764f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/UploadTitulos.vue"
export default component.exports