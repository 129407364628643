var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Loading", {
        attrs: { active: _vm.loadingAgrupador, "is-full-page": true }
      }),
      _c(
        "v-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mensagem,
              expression: "mensagem"
            }
          ],
          staticClass: "mt-2 mb-1",
          attrs: {
            dense: "",
            dismissible: "",
            outlined: "",
            type: _vm.tipoMensagem,
            border: "left"
          }
        },
        [_vm._v(_vm._s(_vm.mensagem))]
      ),
      _vm.mostrarConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarConfirmacao,
              tituloModal: _vm.tituloModal,
              textoModal: _vm.textoModal,
              btnConfirmarEmit: "confirmarUpload"
            },
            on: {
              confirmarUpload: _vm.confirmarUpload,
              fecharModal: function($event) {
                _vm.mostrarConfirmacao = false
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-card",
            { staticClass: "my-2", attrs: { align: "left", justify: "left" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" cloud_upload ")]
                  ),
                  _vm._v("Títulos > Upload de Títulos ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c(
                "v-container",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "4" } },
                        [
                          _c("v-file-input", {
                            ref: "myFileInput",
                            staticClass: "mb-n7",
                            attrs: {
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                              label: "Selecione o arquivo (.xls/.xlsx/ .xlsm)",
                              rules: _vm.rules,
                              "show-size": "",
                              loading: _vm.loadingUpload,
                              disabled: _vm.desabilitaBoxEnvio,
                              chips: "",
                              outlined: "",
                              id: "fileInput",
                              "data-cy": "uploadInput"
                            },
                            on: { change: _vm.uploadFileReference },
                            model: {
                              value: _vm.file,
                              callback: function($$v) {
                                _vm.file = $$v
                              },
                              expression: "file"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mx-auto",
                          attrs: { cols: "12", sm: "12", md: "2" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: _vm.colorBtn,
                                large: "",
                                width: "100%",
                                loading: _vm.loadingUpload,
                                disabled: _vm.uploadOk,
                                "data-cy": "enviarUpload"
                              },
                              on: { click: _vm.upload }
                            },
                            [
                              _vm._v(" Enviar "),
                              _c("v-icon", { staticClass: "ml-2" }, [
                                _vm._v("cloud_upload")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "12", md: "6" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-3",
                                              attrs: { color: "grey lighten-2" }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" help ")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Utilize esse template para fazer upload de títulos para o sistema da Recuperi"
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 white--text",
                              attrs: { color: _vm.colorBtn, fullwidth: "" },
                              on: { click: _vm.templateUpload }
                            },
                            [
                              _c("v-icon", { staticClass: "ma-2" }, [
                                _vm._v("cloud_download")
                              ]),
                              _vm._v(" Baixar Template ")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 white--text",
                              attrs: { color: _vm.colorBtn, fullwidth: "" },
                              on: { click: _vm.templateDicas }
                            },
                            [
                              _c("v-icon", { staticClass: "ma-2" }, [
                                _vm._v("info")
                              ]),
                              _vm._v(" Manual de Upload ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mensagemAlerta,
                      expression: "mensagemAlerta"
                    }
                  ]
                },
                [
                  _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(
                      " Apresentante inativo. Não é possível realizar o Upload de Títulos. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "mx-3 mb-0 pa-3 font-weight-bold text-center",
          attrs: { border: "top", color: "primary", type: "info" },
          model: {
            value: _vm.snackbarApresentante,
            callback: function($$v) {
              _vm.snackbarApresentante = $$v
            },
            expression: "snackbarApresentante"
          }
        },
        [
          _vm._v(
            " Importante: você está logado em: " +
              _vm._s(`${_vm.nomeApresentante} (${_vm.cdApresentante})`) +
              ", através do usuário: " +
              _vm._s(_vm.computedGetUserName) +
              ". "
          ),
          _c("br"),
          _vm._v(
            " Todas as alterações serão aplicadas ao apresentante " +
              _vm._s(`${_vm.nomeApresentante} (${_vm.cdApresentante})`) +
              ". "
          )
        ]
      ),
      _vm.listaAgrupadoresValidos.length > 0
        ? _c(
            "v-alert",
            {
              staticClass: "mx-3 mb-0 mt-3 pa-3 font-weight-bold text-center",
              attrs: { border: "top", color: "orange", type: "warning" }
            },
            [
              _vm._v(
                " Atenção: Ao preencher o Novo Campo Agrupador, certifique-se de que o código está preenchido corretamente "
              )
            ]
          )
        : _vm._e(),
      _vm.mostrarSnackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.mostrarSnackbar,
              timeoutSnackbar: 6000,
              corSnackbar: _vm.corSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.mostrarSnackbar = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          staticClass: "ma-3 pa-3 font-weight-bold",
          attrs: { border: "top", color: "error", type: "info" },
          model: {
            value: _vm.snackbarNumeroExcedido,
            callback: function($$v) {
              _vm.snackbarNumeroExcedido = $$v
            },
            expression: "snackbarNumeroExcedido"
          }
        },
        [
          _vm._v(
            " Erro: Número de títulos excede a quantidade permitida para upload via XLSX. Importante: O limite de títulos para upload através de um arquivo XLSX é de até 3 mil títulos (3000). Caso a quantidade de títulos for maior que 3 mil títulos, recomendamos dividir em 2 ou mais arquivos para o processamento. Para grandes quantidades, recomendamos a utilização de upload via API. "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.titulos,
                      options: _vm.options,
                      "item-class": _vm.itemRowBackground,
                      "server-items-length": _vm.totalTitulos,
                      loading: _vm.loading,
                      "footer-props": {
                        itemsPerPageOptions: [20, 100, 500]
                      },
                      "item-key": "id_arquivo",
                      "show-expand": "",
                      expanded: _vm.expanded,
                      "loading-text": "Pesquisando Histórico de Remessas...",
                      "no-data-text": "Nenhum registro encontrado",
                      "no-results-text": "Nenhuma Remessa Encontrada..."
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.options = $event
                      },
                      "update:expanded": function($event) {
                        _vm.expanded = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.contador_de_titulos_revisao`,
                          fn: function({ item }) {
                            return [
                              item.contador_de_titulos_revisao != 0
                                ? _c(
                                    "span",
                                    {
                                      style: { color: "red" },
                                      attrs: {
                                        title:
                                          "Este arquivo contém títulos em revisão que precisam ser corrigidos, favor acessar 'consulta de títulos'"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.contador_de_titulos_revisao)
                                      )
                                    ]
                                  )
                                : _c("span", { style: { color: "black" } }, [
                                    _vm._v(
                                      _vm._s(item.contador_de_titulos_revisao)
                                    )
                                  ])
                            ]
                          }
                        },
                        {
                          key: `item.st_arquivo`,
                          fn: function({ item }) {
                            return [
                              item.st_arquivo === "E"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "error ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-alert-circle ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "P"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "grey lighten-4 ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [_vm._v(" NÃO CONFIRMADO ")]
                                  )
                                : _vm._e(),
                              item.st_arquivo === "F"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "success ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-check ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "I"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "grey lighten-4 ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-timer-sand ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "C"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "success ma-2 px-2 py-4",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "my-2 mr-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" mdi-check-circle ")]
                                      ),
                                      _vm._v(" CONFIRMADO EM: "),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusConfirmado(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.st_arquivo === "D"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { depressed: "", small: "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v(" cancel ")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatStatusArquivo(item)
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: `item.dt_criacao`,
                          fn: function({ item }) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.formatDataCriacao(item)))
                              ])
                            ]
                          }
                        },
                        {
                          key: "expanded-item",
                          fn: function({ headers, item }) {
                            return [
                              _c("td", { attrs: { colspan: headers.length } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text subtitle font-weight-black"
                                  },
                                  [
                                    _vm._v(
                                      "Nome do Arquivo: " +
                                        _vm._s(item.nm_arquivo_cliente)
                                    )
                                  ]
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.action",
                          fn: function(props) {
                            return [
                              _c("UploadTituloDetalhes", {
                                attrs: {
                                  index: props.index,
                                  detalhe_remessa: props.item
                                },
                                on: { reload: _vm.aplicarFiltros }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                {},
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", align: "left" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("Instruções de Upload")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("DicasUpload", {
                        on: { fecharModal: _vm.closeDialog }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "70%",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogInicio,
            callback: function($$v) {
              _vm.dialogInicio = $$v
            },
            expression: "dialogInicio"
          }
        },
        [
          _c("v-card", {}, [
            _c(
              "div",
              { staticClass: "pa-8" },
              [
                _c(
                  "v-card-text",
                  {
                    staticClass: "font-weight-black display-1",
                    style: { color: _vm.primaryColorFont }
                  },
                  [_vm._v(" Antes de iniciar, faça o download do Template ")]
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { sm: "6" } },
                      [
                        _c("v-card-text", [
                          _vm._v(
                            "Para que possa realizar o upload corretamente, utilize nosso template que está disponível para download na tela de upload no canto superior."
                          )
                        ]),
                        _c("v-card-text", [
                          _vm._v(
                            "Seu upload pode não funcionar caso não utilize nosso template, pois todos os campos são mapeados. "
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-2",
                            attrs: {
                              color: "green darken-1 white--text",
                              "data-cy": "confirmarTemplate"
                            },
                            on: { click: _vm.desativaDialog }
                          },
                          [
                            _c("v-icon", { attrs: { dark: "" } }, [
                              _vm._v(" done ")
                            ]),
                            _vm._v(" OK, vou utilizar o Template ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { sm: "6" } },
                      [
                        _c("v-img", {
                          attrs: {
                            src: require("@/assets/manual/print-botao_pb.png")
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.dialogDicas
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogDicas,
                callback: function($$v) {
                  _vm.dialogDicas = $$v
                },
                expression: "dialogDicas"
              }
            },
            [
              _c(
                "v-card",
                {},
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [_vm._v("Manual de Upload")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _c("ManualUpload", {
                        on: { fecharModal: _vm.closeDialog }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }